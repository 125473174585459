import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import dine from "../../assets/dine.jpeg";
import data from "./mock-data.json";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { CardActionArea, Divider, styled } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useNavigate } from "react-router-dom";
import { EventLocation } from "../../api/create-event";
export interface LadiesEvent {
  id: string;
  name: string;
  dateTime: string;
  dateAdded: string;
  description: string;
  location: EventLocation;
  price: string;
  instagram: string;
  whatsapp: string;
  orderStatus: string;
}
type CardProps = {
  ladiesEvent: LadiesEvent;
};
function titleCase(str: string) {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}
export const LadiesEventCard: React.FC<CardProps> = ({ ladiesEvent }) => {
  let finalData = ladiesEvent;
  if (!ladiesEvent.id) {
    finalData = data;
  }
  const {
    id,
    name,
    dateTime,
    price,
    location,
    description,
    instagram,
    whatsapp,
    orderStatus = "hi",
  } = ladiesEvent;

  const asDate = new Date(parseInt(dateTime));
  const dateSplit = asDate.toDateString().split(" ");
  const dateFormatted = dateSplit[1] + " " + dateSplit[2] + " " + dateSplit[3];
  const timeFormatted =
    asDate.getHours().toString().padStart(2, "0") +
    ":" +
    asDate.getMinutes().toString().padStart(2, "0");
  const formattedName = titleCase(name);

  const StyledCard = styled(Card)(({ theme }) => ({
    width: "100%",
    background: "#fffcff",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    [theme.breakpoints.up("sm")]: {
      width: "450px",
    },
  }));

  const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
    "&:hover": {
      backgroundColor: "#fff2ff",
    },
  }));
  const navigate = useNavigate();
  return (
    <StyledCard
      sx={{
        width: "100%",
      }}
      id={id}
    >
      <StyledCardActionArea
        onClick={() => {
          navigate(`/event/${id}`);
        }}
      >
        <CardMedia sx={{ height: 140 }} image={dine} title="green iguana" />
        <CardContent sx={{ padding: "10px" }}>
          <Typography gutterBottom variant="h6" component="div">
            {formattedName}
          </Typography>
          <Typography
            sx={{
              width: "400px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            width={"100%"}
            variant="subtitle1"
            color="text.secondary"
          >
            {description}
          </Typography>

          <div className="social-media">
            <AccessTimeIcon style={{ color: "#F6B9DE" }} fontSize="small" />
            <p>
              {dateFormatted} | {timeFormatted}
            </p>
            <FmdGoodOutlinedIcon
              style={{ color: "#F6B9DE" }}
              fontSize="small"
            />
            <p>{location.lng}</p>
            <AccountBalanceWalletIcon
              style={{ color: "#F6B9DE" }}
              fontSize="small"
            />
            <p> AED {price}</p>
          </div>
        </CardContent>

        <Divider variant="middle" />
      </StyledCardActionArea>
    </StyledCard>
  );
};
