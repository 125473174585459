import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import { ContactUs } from "./pages/contact-us";
import { EventDetails } from "./pages/event-details";
import { Home } from "./pages/home";
import { ProcessingOrder } from "./pages/processing-order";
import { ShareEvent } from "./pages/advertise-event";

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/error" element={<h1>Oops</h1>} />
        <Route path="/contact-us" element={<ContactUs/>} />
        <Route path="/advertise-event" element={<ShareEvent />} />
        <Route path="/event/:eventId" element={<EventDetails />} />
        <Route path="/processing-order" element={<ProcessingOrder />} />
      </Routes>
    </div>
  );
}

export default App;
