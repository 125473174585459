import { Button, TextField } from "@mui/material";
import "./advertise-event.css";
import { useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { DateTimePickerComp } from "../DateTimePicker/date-time-picker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import LoadingSpinner from "../common/loading";
import Categories from "./category/categories";
import MapWithSearch from "../common/MapWithSearch";
import { createEvent } from "../../api/create-event";

export const AdvertiseEventForm = () => {
  const [createLadiesEvent, { data, loading, error }] = createEvent();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      location: { lng: 12, lat: 12, name: "" },
      organiserName: "",
      organiserEmail: "",
      price: "",
      instagram: "",
      whatsapp: "",
      dateAdded: 0,
      dateTime: dayjs(new Date()),
      category: "Other",
    },
  });

  type FormFields =
    | "title"
    | "description"
    | "location"
    | "organiserName"
    | "organiserEmail"
    | "price"
    | "dateTime"
    | "instagram"
    | "whatsapp"
    | "category";

  const textField = (
    registerName: FormFields,
    label: string,
    customPattern: any = undefined,
    maxLength?: number,
    input?: string
  ) => {
    return (
      <>
        <div className="text">
          <TextField
            {...register(registerName, {
              required: "This field is required",
              pattern: { ...customPattern },
            })}
            fullWidth
            id="outlined-basic"
            label={label}
            variant="outlined"
            type={input ? input : "text"}
            inputProps={{ maxLength }}
          />
          {errors[registerName] && (
            <p className="errorSpan">
              {customPattern?.message
                ? customPattern?.message
                : "This field is required"}
            </p>
          )}
        </div>
        <br />
      </>
    );
  };
  interface IFormFields {
    title: string;
    description: string;
    location: { lng: number; lat: number; name: string };
    organiserName: string;
    organiserEmail: string;
    price: string;
    instagram: string;
    whatsapp: string;
    dateTime: any;
    dateAdded: number;
    category: string;
  }

  const onSubmit = async (data: IFormFields) => {
    const formattedDate = new Date(data.dateTime.format()).getTime();
    const dateAdded = new Date().getTime() + "";
    const orderUniqueId = uuidv4(); 

    console.log("lala data", data);
    await createLadiesEvent({
      variables: {
        data: {
          name: data.title,
          id: orderUniqueId,
          dateTime: formattedDate + "",
          dateAdded: dateAdded,
          "location": {
            "create": {
              "lng": -0.1278,
              "lat": 51.5074,
              "name": "Central London"
            }
          },
          description: data.description,
          price: data.price,
          instagram: data.instagram,
          whatsapp: data.whatsapp,
          orderStatus: "pending",
          category: data.category,
        },
      },
    }).then(() => {
      window.location.href = `https://buy.stripe.com/test_3csaIbbUZfeB30A3cd?client_reference_id=${orderUniqueId}`;
    });
  };
  if (loading || data) return <LoadingSpinner />;
  if (error) return <h1>Something went wrong, we're working on it</h1>;

  return (
    <div className="advertise-event-form">
      <h2 className="form-title">Advertise your event</h2>
      <form>
        {textField("title", "Event Title", 35)}
        {textField("description", "Event Description", true, 100)}
        <div className="datetime">
          <DateTimePickerComp control={control} />
        </div>
        <br />
        {textField("organiserName", "Organiser Name", true, 100)}
        {textField(
          "organiserEmail",
          "Organiser Email",
          {
            value: /\S+@\S+\.\S+/,
            message: "Enter a valid email",
          },
          100
        )}

        {textField(
          "price",
          "Price AED",
          {
            message: "Enter a price for your event",
          },
          100,
          "number"
        )}
        {textField("instagram", "Instagram", undefined, 100)}
        {textField("whatsapp", "Whatsapp", undefined, 100)}
        <div className="category">
          <Categories control={control} />
        </div>
        <br />

        <MapWithSearch control={control} />

        <div className="submitButton">
          <Button
            onClick={handleSubmit(onSubmit)}
            style={{
              marginTop: "5%",
              color: "#b586ac",
              backgroundColor: "#fef8fa",
              textTransform: "none",
              fontSize: "1.2em",
              display: "flex",
            }}
            size="large"
            variant="contained"
            href="#contained-buttons"
          >
            Submit Your Event
          </Button>
        </div>
      </form>
      <br />
    </div>
  );
};
