
import "./sort.css";
import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { FormControl, InputLabel } from "@mui/material";

enum SortOptions {
  "Recently Added" = "Recent",
  "Happening Soon" = "Soon",
}

export const SortForm = () => {
  const [sort, setSort] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setSort(event.target.value as string);
  };

  const handleClick = (event: any) => {
    if (event.target.innerText === sort) {
      setSort("");
    }
  };

  return (
    <>
      <div className="sort">

        <Box
          sx={{
            minWidth: 120,
            maxWidth: 600,
          }}
        >
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Sort By
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={sort}
              onClick={handleClick}
              onChange={handleChange}
              label="Sort By"
            >
              {Object.keys(SortOptions).map((sort) => (
                <MenuItem key={sort} value={sort}>
                  {sort}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      <br/>

      </div>
   
    </>
  );
};
