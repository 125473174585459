import { useQuery, gql } from "@apollo/client";
import { EventLocation } from "./create-event";


const GET_ALL_EVENTS_BY_RECENTLY_ADDED = gql`
  query {
    ladiesEventsSortedByRecentlyAdded {
      data {
        id
        name
        dateAdded
        dateTime
        description
        location {
          lng
          lat
          name
        }
        price
        instagram
        whatsapp
        orderStatus
      }
    }
  }
`;

interface RecentlyAddedEvent {
  id: string;
  name: string;
  dateAdded: string;
  dateTime: string;
  location: EventLocation;
  description: string;
  price: string;
  instagram: string;
  whatsapp: string;
  orderStatus: string;
}

interface RecentlyAdded {
  ladiesEventsSortedByRecentlyAdded: {
    data: RecentlyAddedEvent[];
  };
}

export const getEventsByRecentlyAdded = () => {
  const { data, loading, error } = useQuery<RecentlyAdded>(
    GET_ALL_EVENTS_BY_RECENTLY_ADDED
  );

  return { data: data?.ladiesEventsSortedByRecentlyAdded.data, loading, error };
};
