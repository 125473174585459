import * as React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";

const Categories: React.FC<{ control: any }> = ({ control }) => {

  return (
    <Stack spacing={2} sx={{ width: 300 }}>
      <Controller
        name="category"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => ( // Use the 'value' prop from Controller

          <Autocomplete
            id="free-solo-demo"
            options={categories}
            value={value || ""} // Set the value of Autocomplete based on form value
            onChange={(_, newValue) => {
              onChange(newValue); // Update form value
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a category"
              />
            )}
          />
        )}
      />
    </Stack>
  );
};

const categories = [
  "Sports",
  "Party",
  "Food and Hangout",
  "Networking",
  "Other",
];

export default Categories;
