import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import loeLogo from './loelogo.jpg'
import "./navbar.css";
import AccountMenu from "./Menu";
export default function Navbar() {
  const navigate = useNavigate();
  return (
      <AppBar style={{ background: "#FDF1F9", height:"6em",display:"flex", flexDirection:"row", justifyContent:"space-around"
       }} position="static">
         <img onClick={()=> navigate("/")} src={loeLogo}  />
         <AccountMenu/>
      </AppBar>
  );
}
