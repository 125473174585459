import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import "./loading.css"
export default function LoadingSpinner() {
  return (
    <div className="spinner">
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    </div>
  );
}