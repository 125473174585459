import { AdvertiseEventForm } from "../components/AdvertiseEventForm/advertise-event-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export const ShareEvent = () => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AdvertiseEventForm />
      </LocalizationProvider>
    </div>
  );
};
