import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import "./date-picker.css";
import { Controller } from "react-hook-form";

export const DateTimePickerComp: React.FC<{ control: any }> = ({ control }) => {
  return (
    <div className="date-time-picker">
      <Controller
        name="dateTime"
        control={control}
        
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
            <DateTimePicker
              label="Date and Time"
              value={value}
              defaultValue={dayjs(new Date())}
              onChange={onChange}
            />
          </DemoContainer>
        )}
      />
    </div>
  );
};
