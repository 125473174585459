import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { loadGoogleMaps } from "../../loadGoogleMaps";

const MapWithSearch: React.FC<{ control: any }> = ({ control }) => {
  const [map, setMap] = useState<google.maps.Map>();
  const [latLng, setLatLng] = useState({ lat: 25.197381, lng: 55.274387 }); // Default values
  const mapRef = useRef<HTMLDivElement>(null);
  const searchBoxRef = useRef<HTMLInputElement>(null);
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    loadGoogleMaps().then(() => {
      if (mapRef.current) {
        const initialMap = new google.maps.Map(mapRef.current, {
          center: latLng,
          zoom: 12,
        });
        const initialMarker = new google.maps.Marker({
          position: latLng,
          map: initialMap,
        });
        setMap(initialMap);
        setMarker(initialMarker);
      }
    });
  }, []);

  useEffect(() => {
    if (map && searchBoxRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(searchBoxRef.current);
      autocomplete.bindTo("bounds", map);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }

        if (marker) {
          marker.setMap(null);
        }
        const newMarker = new google.maps.Marker({
          position: place.geometry.location,
          map: map,
          title: place.name,
        });

        setMarker(newMarker);
        setLatLng({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }); // Update state

        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }
      });
    }
  }, [map]);

  return (
    <Controller
      name="location"
      control={control}
      rules={{ required: true }}
      render={({ field: { onChange, value } }) => (
        <div>
          <input
            ref={searchBoxRef}
            type="text"
            placeholder="Search places"
            style={{
              paddingTop: "5%",
              paddingBottom: "5%",
              marginBottom: "5%",
              width: "100%",
            }}
            onChange={e => {
              onChange({ ...value, name: e.target.value, ...latLng });
            }}
          />
          <div ref={mapRef} style={{ height: "400px", width: "100%" }} />
        </div>
      )}
    />
  );
};

export default MapWithSearch;
