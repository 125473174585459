import React, { useEffect, useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { loadGoogleMaps } from "../../loadGoogleMaps";

export const MyMap: React.FC = () => {
  const [map, setMap] = useState<google.maps.Map>();
  const [marker, setMarker] = useState<google.maps.Marker>();
  const mapRef = useRef<HTMLDivElement>(null);
  const [placeName, setPlaceName] = useState<string>(""); // State to store place name

  useEffect(() => {
    loadGoogleMaps().then(() => {
      if (mapRef.current) {
        const initialMap = new google.maps.Map(mapRef.current, {
          center: { lat: 25.186658855971643, lng: 55.3022780183291 },
          zoom: 15,
        });

        const initialMarker = new google.maps.Marker({
          position: { lat: 25.186658855971643, lng: 55.3022780183291 },
          map: initialMap,
        });

        setMarker(initialMarker);

        const service = new google.maps.places.PlacesService(initialMap);
        service.nearbySearch(
          {
            location: { lat: 25.148290697354675, lng: 55.285459490759926 },
            radius: 10, // Search within a small radius
          },
          (results: any, status) => {
            if (
              status === google.maps.places.PlacesServiceStatus.OK &&
              results[0]
            ) {
              console.log("lala res", results);
              setPlaceName(results[0].name);
            }
          }
        );
      }
    });
  }, []);

  return (
    <>
      <div ref={mapRef} style={{ height: "400px", width: "100%" }} />
    </>
  );
};
