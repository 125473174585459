import { useMutation, gql, useQuery } from "@apollo/client";

const CREATE_LADIES_EVENT = gql`
  mutation ($data: LadiesEventInput!) {
    createLadiesEvent(data: $data) {  
    id
    name
    dateTime
    dateAdded
    description
    location {
      lng
      lat
      name
    }
    price
    instagram
    whatsapp
    orderStatus
    category
  }
  }
`;
interface LadiesEvent {
  ladiesEventsSortedByRecentlyAdded: {
    data: Array<{
      id: string;
      name: string;
      dateAdded: string;
      time: string;
      location: EventLocation;
      price: string;
      instagram: string;
      whatsapp: string;
      date: string;
    }>;
  };
}

export type EventLocation = {
lng: number
lat: number
name:string
}

export const createEvent = (ladiesEventData?: LadiesEvent) => {
  return useMutation(CREATE_LADIES_EVENT);
};
