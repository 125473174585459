import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./page.css";
export const ProcessingOrder = () => {
  const navigate = useNavigate();

  return (
    <div className="processing-order">
      <h3>Thank you for submitting your order to advertise with us</h3>

      <h4>
        We are processing your payment. Once we receive it, your event will show
        on the home page
      </h4>

      <Button
        onClick={() => navigate("/")}
        style={{
          color: "#b586ac",
          backgroundColor: "#fef8fa",
          fontWeight: "bold",
          fontFamily: "Roundhand, cursive",
          textTransform: "none",
          fontSize: "1.2em",
          display: "flex",
        }}
        size="large"
        variant="contained"
      >
        Back To Home Page
      </Button>
    </div>
  );
};
