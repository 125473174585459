import { LadiesEvent, Card } from "./card";
import LoadingSpinner from "../common/loading";
import { SortForm } from "../SortForm/sort-form";
import "./card.css";
import { getEventsByRecentlyAdded } from "../../api/events-by-recently-added";
import {LadiesEventCard} from "./LadiesEventCard";
import React from "react";


export const CardsList = () => {
  const { data, loading, error } = getEventsByRecentlyAdded();
  const modifiedData = data
  if (loading) return <LoadingSpinner />;


  else if (error) return <h1>Error.....</h1>;
  return (
    <>
      <SortForm />
      <div className="card-list">
        {modifiedData &&
          modifiedData.map((ladiesEvent: LadiesEvent) => (
          // Only show events where the organiser has paid to advertise for us 
          ladiesEvent.orderStatus === "paid" && (
            <React.Fragment key={ladiesEvent.id}>
              <LadiesEventCard ladiesEvent={ladiesEvent} />
              <br/>
            </React.Fragment>
          )
          ))}

      </div>
    </>
  );
};
