import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { findEventByID } from "../api/event-by-id";
import { EventDetailsCard } from "../components/Cards/EventDetailCard";
import LoadingSpinner from "../components/common/loading";

export const EventDetails = () => {
  const { eventId } = useParams();
  const { data, loading, error } = findEventByID(eventId ?? "");
  const modifiedData = data;
  console.log("event deets mod", modifiedData);

  if (error)
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <h1>Error...</h1>
      </Box>
    );
  if (loading) return <LoadingSpinner />;
  return modifiedData ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <EventDetailsCard ladiesEvent={modifiedData} />
    </Box>
  ) : (
    <div></div>
  );
};
