import { useQuery, gql } from "@apollo/client";
import { EventLocation } from "./create-event";

const FIND_EVENT_BY_ID = gql`
  query ($id: ID!) {
    findEventByID(id: $id) {
        id
        name
        dateAdded
        dateTime
        description
        location {
          lng
          lat
          name
        }
        price
        instagram
        whatsapp
    }
  }
`;

interface EventByID {
  findEventByID: {
      id: string;
      name: string;
      dateAdded: string;
      dateTime: string;
      location: EventLocation;
      description: string;
      price: string;
      instagram: string;
      whatsapp: string;
      orderStatus:string;
  };
}

export const findEventByID = (eventId: string) => {
  const { data, loading, error } = useQuery<EventByID>(FIND_EVENT_BY_ID, {
    variables: { id: eventId },
  });
  return { data: data?.findEventByID    , loading, error };
};
